import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import AboveFooterSection from '../../components/AboveFooterSection'

import Spacing from '../../components/partials/Spacing'
import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

import alerts from '../../resources/images/usecases/secure/alerts.svg'
// import application_permissions from '../../resources/images/usecases/secure/application-permissions.png'
import audit_logs from '../../resources/images/usecases/secure/audit-logs.svg'
import ccpa from '../../resources/images/usecases/secure/ccpa.svg'
import change_management from '../../resources/images/usecases/secure/change-management.svg'
import data_masking from '../../resources/images/usecases/secure/data-masking.svg'
import detect_notify from '../../resources/images/usecases/secure/detect-notify.svg'
import fine_grained_permissions from '../../resources/images/usecases/secure/fine-grained-permissions.svg'
import gdpr from '../../resources/images/usecases/secure/gdpr.svg'
import hipaa from '../../resources/images/usecases/secure/hipaa.svg'
import identity_provider from '../../resources/images/usecases/secure/identity-provider.svg'

import limit_data_access from '../../resources/images/usecases/secure/limit-data-access.svg'
import multi_tenant from '../../resources/images/usecases/secure/multi-tenant.svg'
import pci_dss from '../../resources/images/usecases/secure/pci-dss.svg'
import regulations_compliance from '../../resources/images/usecases/secure/regulations-compliance.svg'
import sec from '../../resources/images/usecases/secure/sec.svg'
import secret_management from '../../resources/images/usecases/secure/secret-management.svg'
import users_compliance from '../../resources/images/usecases/secure/users-compliance.svg'

import ModalImage from 'react-modal-image'
const secure = () => {
  const data = useStaticQuery(graphql`
    query SecureSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Usecases_Secure" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Hero Lenses"
      />
      <section className="hero-margin pb-5">
        <div className="container-1 ipad-margin-top">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-6 pt-0">
              <h1 className="pr-5 pb-4">
                Kafka security & compliance to reduce risk for your streaming
                data projects
              </h1>
              <div className="pr-5">
                <p className="hero-description-dark pr-5">
                  Sure, you may have secure Kafka infrastructure, but dropping
                  your guard on data operations in Kafka spells disaster:
                  breaching compliance, outages, or worse - finding your data
                  for sale on Craigslist.
                </p>
              </div>
              <div className="roll-button">
                <a className="bg-red text-white" href="/start/">
                  <span data-hover="Get Started">Get Started</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 pt-0">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half"
                src="../../resources/images/usecases/secure/hero-image.png"
                placeholder="tracedSVG"
                alt="Data Governance and Security"
              />
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="bg-cyan mobile-margin-top">
        <div className="container-1 py-5">
          <div className="text-center">
            <h2 className="h1-like pb-4 w-90 mx-auto">
              Do you have a “don’t look, don’t tell” approach to Kafka security?
            </h2>{' '}
            <p className="w-90 mx-auto">
              Onboarding critical data to Apache Kafka can get messy - leading
              to your security teams asking you: what decade are these security
              controls from?
            </p>
            <p className="w-90 mx-auto">
              How can you make sure users can access the right Kafka resources,
              so you can get your project to production and not worry about a
              3am call from your infosec team?
            </p>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-5 col-10 mb-3">
              <div className="text-center">
                <h2 className="h1-like">
                  Why secure your Kafka data operations?
                </h2>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <div className="w-100 px-5">
                  <StaticImage
                    placeholder="tracedSVG"
                    src="../../resources/images/usecases/secure/reduce-risk.png"
                    alt="Reduce Risk"
                  />
                </div>
                <div className="pb-4 px-2 mt-3">
                  <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                    Reduce Risk
                  </h3>
                  <p className="pt-3 text-center">
                    A single endpoint to operate Kafka means a smaller attack
                    surface.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <div className="w-100 px-5">
                  <StaticImage
                    src="../../resources/images/usecases/secure/get-to-production-faster.svg"
                    placeholder="tracedSVG"
                    alt="Get to Production Faster"
                  />
                </div>
                <div className="pb-4 px-2 mt-3">
                  <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                    Get to production faster
                  </h3>
                  <p className="pt-3 text-center">
                    Get the all-clear from your compliance team with identity
                    and access management.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <div className="w-100 px-5">
                  <StaticImage
                    src="../../resources/images/usecases/secure/faster-breach-response.png"
                    placeholder="tracedSVG"
                    alt="Faster Breach Response"
                  />
                </div>
                <div className="pb-4 px-2 mt-3">
                  <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                    Speed up breach response
                  </h3>
                  <p className="pt-3 text-center">
                    Kafka observability & user activity audits get you ready for
                    an incident response.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="bg-dark mobile-margin-top">
        <div className="container-1 py-5">
          <div className="text-center">
            <div className="w-15 mb-4 mobile-w-50 mx-auto">
              <StaticImage
                className="img-fluid"
                src="../../resources/images/usecases/secure/babylon.png"
                placeholder="tracedSVG"
                alt="Babylon"
              />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-6 col-10">
              <p className="text-white f-20 lh-35 text-center">
                “We are a healthcare company, the integration with Okta, RBAC
                and audit features are key for us and allow us to open up the
                platform to wider teams”
              </p>
            </div>
            <div className="col-sm-7 col-10">
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                <p className="f-18 fw-900 text-center text-white mobile-text-center">
                  {' '}
                  Lead Data Operations Engineer, Jeremy Frenay
                </p>
              </div>
              <div className="text-center">
                <a href="/customers/">
                  <p className="primary-text f-18 fw-900 text-center pt-2">
                    Read customers stories <i className="red-pointer-right"></i>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex justify-content-center">
            <div className="col-md-8">
              <div className="text-center px-2">
                <h2 className="h1-like">
                  Apache Kafka security best practices, with comprehensive
                  compliance
                </h2>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap pt-5 mt-5 align-items-center mobile-margin-reset">
            <div className="col-md-5 col-11 ">
              <h2>How can we control data access and platform operations?</h2>
              <p className="pr-2">
                More fine-grained than ACLs, use Lenses unified security model
                over a UI and API to provide flexible access management.
              </p>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6  col-12 ">
              <ModalImage
                className="pt-2 w-100"
                small={limit_data_access}
                large={limit_data_access}
                alt="Limit Data Access"
              />
            </div>
          </div>

          <div className="d-flex flex-wrap flex-column-reverse flex-sm-row py-5 align-items-center ">
            <div className="col-md-6  col-12">
              <ModalImage
                className="pt-2 w-100"
                small={detect_notify}
                large={detect_notify}
                alt="Control data access"
              />
            </div>
            <div className="offset-md-1 offset-0"></div>
            <div className="col-md-5 col-12">
              <h2>How can we send data operations audits into my SIEM?</h2>
              <p>
                Generate audits for all user operations and integrate them with
                3rd party SIEM systems such as{' '}
                <a href="/blog/2021/01/kafka-to-splunk-connect/"> Splunk</a>.
              </p>
            </div>
          </div>

          <div className="d-flex flex-wrap py-5 align-items-center ">
            <div className="col-md-5 col-11">
              <h2>
                How can we discover data and mask fields to meet compliance?
              </h2>
              <p>
                Intelligently{' '}
                <a href="/blog/2020/12/life-of-pii-data-masking-and-compliance-for-apache-kafka/ ">
                  <b>detect metadata</b>{' '}
                </a>
                across your applications, connectors and data streams, and then
                configure pseudonymization.
              </p>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6  col-12 ">
              <ModalImage
                className="pt-2 w-100"
                small={data_masking}
                large={data_masking}
                alt="Data Masking"
              />
            </div>
          </div>

          <div className="d-flex flex-wrap flex-column-reverse flex-sm-row py-5 align-items-center ">
            <div className="col-md-6  col-12 ">
              <ModalImage
                className="pt-2 w-100"
                small={users_compliance}
                large={users_compliance}
                alt="Users compliance"
              />
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-5 col-12">
              <h2>How can I best authenticate my users to meet compliance?</h2>
              <p>
                Authenticate and authorize users via pre-built integrations with
                top Identity SSO Providers including Okta, Keycloak, OneLogin &
                Google SSO
              </p>
            </div>
          </div>

          <div className="d-flex flex-wrap pt-5 align-items-center ">
            <div className="col-md-5 col-11 ">
              <h2>How can we make our Kafka data platform multi-tenant?</h2>
              <p>
                Protect data access in Kafka, Elasticsearch and Postgres,
                <a href="/blog/2020/12/kafka-connect-top-compliance-controls/">
                  {' '}
                  Kafka Connect
                </a>{' '}
                data integration connectors and more, all with namespaces!
              </p>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6  col-12 ">
              <ModalImage
                className="pt-2 w-100"
                small={multi_tenant}
                large={multi_tenant}
                alt="Kafka Multi tenant"
              />
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="footer-color py-5 mobile-margin-top">
        <div className="text-center">
          <div className="container-1">
            <h2 className="h1-like">Kafka security architecture</h2>
          </div>
          <StaticImage
            className="w-75 mobile-w-100"
            src="../../resources/images/usecases/secure/kafka-security-architecture.svg"
            placeholder="tracedSVG"
            alt="Governance & Productivity"
          />
        </div>
      </section>

      <Spacing />

      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-6 col-md-8 col-12">
              <h2 className="h1-like cera-pro text-center">
                Kafka security & compliance components
              </h2>
            </div>
            <div className="col-sm-11 col-12 mobile-margin-reset">
              <div className="d-flex flex-wrap">
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={alerts}
                    alt="User management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    ALERT RULES & NOTIFICATIONS
                  </p>
                </div>
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={fine_grained_permissions}
                    alt="Quota and ACL management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    FINE GRAINED PERMISSIONS
                  </p>
                </div>
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={change_management}
                    alt="Data masking"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    CHANGE MANAGEMENT
                  </p>
                </div>
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={audit_logs}
                    alt="Consumer & Offset Management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    AUDIT <br /> LOGS
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={regulations_compliance}
                    alt="Topic Management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    REGULATIONS & <br /> COMPLIANCE
                  </p>
                </div>
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={identity_provider}
                    alt="Connector Management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    IDENTITY <br /> PROVIDERS
                  </p>
                </div>
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={secret_management}
                    alt="Fine grained permissions"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    SECRET <br /> MANAGEMENT
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="bg-usecase-secure py-5 mobile-margin-top">
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-7 col-12">
              <p className="f-16 text-center cera-pro text-uppercase text-white">
                {' '}
                Case Study
              </p>
              <div className="text-center pb-4 pt-4">
                <StaticImage
                  src="../../resources/images/usecases/secure/avanza-logo.svg"
                  placeholder="tracedSVG"
                  alt="Avanza"
                />
              </div>
              <h2 className="fw-900 text-center text-white h1-like">
                Leading the Swedish savings market by safely liberating Kafka
                data
              </h2>

              <p className="f-20 text-white text-center mb-0">
                How did investment platform Avanza win &gt; 1 million customers
                by enabling developers with a secure data platform?
              </p>

              <div className="mt-4 text-center">
                <a className="primary-text" href="/customers/avanza/">
                  Read more <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center">
            <h2 className="h1-like w-50 mx-auto">
              Key data protection regulations? <br />
              Covered:
            </h2>
          </div>
          <div className="d-flex flex-wrap justify-content-sm-between justify-content-center align-items-center">
            <div className="col-sm-2 mt-5 col-7 text-center">
              <img className="img-fluid" src={gdpr} alt="GDPR" />
              <p className="text-center mt-4 f-20 fw-900 cera-pro">GDPR</p>
            </div>
            <div className="col-sm-2 mt-5 col-7 text-center">
              <img className="img-fluid" src={sec} alt="SEC" />
              <p className="text-center mt-3 f-20 fw-900 cera-pro">SEC</p>
            </div>
            <div className="col-sm-2 mt-5 col-7 text-center">
              <img className="img-fluid" src={ccpa} alt="CCPA" />
              <p className="text-center mt-3 f-20 fw-900 cera-pro">CCPA</p>
            </div>
            <div className="col-sm-2 mt-5 col-7 text-center">
              <img className="img-fluid" src={hipaa} alt="HIPAA" />
              <p className="text-center mt-3 f-20 fw-900 cera-pro ml-1">
                HIPAA
              </p>
            </div>
            <div className="col-sm-2 mt-5 col-7 text-center">
              <img className="img-fluid" src={pci_dss} alt="PCI/DSS" />
              <p className="text-center mt-5 f-20 fw-900 cera-pro">PCI/DSS</p>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/secure/blog_gdpr.png"
                  placeholder="tracedSVG"
                  alt="GDPR Compliance Blog"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">BLOG</p>
                  <div>
                    <h3 className="f-19 d-inline">Architecting Kafka</h3>{' '}
                    <p className="cera-pro d-inline"> for GDPR compliance </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2021/03/architecting-apache-kafka-for-gdpr-compliance/"
                  >
                    Read blog <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/secure/blog_kafka_connect.jpeg"
                  placeholder="tracedSVG"
                  alt="Kafka Connect Blog"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Blog</p>
                  <div>
                    <p className="cera-pro d-inline">Don’t trust </p>
                    <h3 className="f-19 d-inline"> Kafka Connect</h3>{' '}
                    <p className="cera-pro d-inline">with your secrets</p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2020/05/securing-kafka-connect-azure-keyvault/"
                  >
                    Read blog <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/secure/blog_splunk_to_kafka.jpeg"
                  placeholder="tracedSVG"
                  alt="Kafka to Splunk Blog"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Blog</p>
                  <div>
                    <h3 className="f-19 d-inline">Kafka to Splunk</h3>{' '}
                    <p className="cera-pro d-inline">
                      : Data mesh for security & IT.
                    </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2021/01/kafka-to-splunk-connect/"
                  >
                    Read blog <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Begin strengthening your Kafka security with Lenses'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}
export default secure
